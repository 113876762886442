import MainNavigation from "./MainNavigation";
import classes from './Layout.module.css'

const layout=(props)=>{
    return <>
    <MainNavigation/>
        <main className={classes.main}>
            {props.children}
        </main>
    </>

};
export default layout;